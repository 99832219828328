<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="pt-14 space-y-8">
				<table class="table_form line-bin">
					<colgroup>
						<col style="width:130px;" />
						<col style="width:auto;" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">
								<label for="label5">소속</label>
							</th>
							<td>{{ this.formData.deptNmPath }}</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">상담원</label>
							</th>
							<td>{{ this.formData.agtNm }} [{{ this.formData.agtId }}]</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">근무일</label>
							</th>
							<td>{{ this.formData.workDt }}</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">근무유형</label>
							</th>
							<td>
								{{ this.formData.workBasicNm }}
								({{ this.formData.workBasicStartTime }} - {{ this.formData.workBasicEndTime }})
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">현재 근무일</label>
							</th>
							<td>
								{{ this.$_commonlib.formatDate(this.formData.ymd, 'YYYYMMDD', 'YYYY-MM-DD') }}
								({{ this.formData.firstLoginDt }} - {{ this.formData.lastLogoutDt }})
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">현재 출결 정보</label>
							</th>
							<td>{{ codes.originAttendanceState.find(a => a.id === this.formData.attendanceState).value }}</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">변경 근태 정보 <span class="icon_require">필수항목</span></label>
							</th>
							<td>
								<DxSelectBox
									placeholder="변경 근태 정보"
									:styling-mode="config.stylingMode"
									v-model="formData.editAttendanceState"
									:items="codes.attendanceState"
									display-expr="value"
									value-expr="id"
									width="300"
									@value-changed="onChangeState"
								>
									<DxValidator>
										<DxRequiredRule message="변경 근태 정보는 필수입니다." />
									</DxValidator>
								</DxSelectBox>
							</td>
						</tr>

						<tr v-if="formData.editAttendanceState == 'normalFl'">
							<th scope="row">
								<label for="label5">변경 출결 시간</label>
							</th>
							<td> {{ this.formData.loginTimeEdit }} - {{ this.formData.logoutTimeEdit }} </td>
						</tr>

						<tr v-if="formData.editAttendanceState == 'tardinessFl'">
							<th scope="row">
								<label for="label5">변경 출결 시간</label>
							</th>
							<td>
								<div class="flex space-x-4">
									<div>
										<DxDateBox
											styling-mode="outlined"
											:width="100"
											type="time"
											dateSerializationFormat="HH:mm"
											display-format="HH:mm"
											:min="formData.workBasicStartTime"
											:disabledDates="config.disabledDates"
											v-model="formData.loginTimeEdit"
										>
											<DxValidator>
												<DxRequiredRule message="변경 출결 시간은 필수입니다." />
											</DxValidator>
										</DxDateBox>
									</div>
									<div class="mt-1">~</div>
									<div class="mt-1">
										{{ this.formData.logoutTimeEdit }}
									</div>
								</div>
							</td>
						</tr>

						<tr v-if="formData.editAttendanceState == 'dayoffFl'">
							<th scope="row">
								<label for="label5">변경 출결 시간</label>
							</th>
							<td>
								<div class="flex space-x-4">
									<div class="mt-1">
										{{ this.formData.loginTimeEdit }}
									</div>
									<div class="mt-1">~</div>
									<div>
										<DxDateBox
											styling-mode="outlined"
											:width="100"
											type="time"
											dateSerializationFormat="HH:mm"
											display-format="HH:mm"
											:min="formData.workBasicStartTime"
											:disabledDates="config.disabledDates"
											v-model="formData.logoutTimeEdit"
										>
											<DxValidator>
												<DxRequiredRule message="변경 출결 시간은 필수입니다." />
											</DxValidator>
										</DxDateBox>
									</div>
								</div>
							</td>
						</tr>

						<tr v-if="formData.editAttendanceState == 'absentFl'">
							<th scope="row">
								<label for="label5">변경 출결 시간</label>
							</th>
							<td> {{ this.formData.loginTimeEdit }} {{ this.formData.logoutTimeEdit }} </td>
						</tr>

						<tr>
							<th scope="row">
								<label for="label5">변경사유</label>
							</th>
							<td>
								<DxTextArea
									:styling-mode="config.stylingMode"
									class="mr-4"
									:show-clear-button="true"
									width="300"
									:height="100"
									v-model="formData.approvalRequestReason"
								>
								</DxTextArea>
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">첨부파일</label>
							</th>
							<td>
								<DxTextBox
									:styling-mode="config.stylingMode"
									v-model="formData.originalFileName"
									:show-clear-button="true"
									id="dropzone-external"
									width="300"
									height="30"
								/>
								<DxFileUploader
									id="file-uploader"
									upload-mode="useButtons"
									dialog-trigger="#dropzone-external"
									:visible="false"
									@value-changed="uploadFile"
								/>
							</td>
						</tr>
						<tr>
							<th scope="row">
								<label for="label5">결재정보</label>
							</th>
							<td>
								<div class="space-y-4">
									<div class="space-x-6 flex items-center">
										<span class="w-10">구분</span>
										<span class="w-40 flex justify-center">결재자명</span>
										<span class="w-20 flex justify-center">승인여부</span>
									</div>
									<div class="space-x-6 flex items-center">
										<span class="w-10">1차</span>
										<span class="w-40">
											<DxSelectBox
												placeholder="1차 결재자"
												:data-source="approval.firstAgt"
												display-expr="agtNm"
												value-expr="agtId"
												v-model="formData.primaryApproverId"
												:styling-mode="config.stylingMode"
											>
												<DxValidator>	
													<DxRequiredRule message="결재자는 필수입니다." />
												</DxValidator>
											</DxSelectBox>
										</span>
										<span class="w-20 flex justify-center">
											{{ formData.primaryApprovalStatusNm }}
										</span>
									</div>
									<div class="space-x-6 flex items-center">
										<span class="w-10">2차</span>
										<span class="w-40">
											<DxSelectBox
												placeholder="2차 결재자"
												:data-source="approval.secondAgt"
												display-expr="agtNm"
												value-expr="agtId"
												v-model="formData.secondaryApproverId"
												:styling-mode="config.stylingMode"
											>
											</DxSelectBox>
										</span>
										<span class="w-20 flex justify-center">
											{{ formData.secondaryApprovalStatusNm }}
										</span>
									</div>
									<div class="space-x-6 flex items-center">
										<span class="w-10">3차</span>
										<span class="w-40">
											<DxSelectBox
												placeholder="3차 결재자"
												:data-source="approval.thirdtAgt"
												display-expr="agtNm"
												value-expr="agtId"
												v-model="formData.tertiaryApproverId"
												:styling-mode="config.stylingMode"
											>
											</DxSelectBox>
										</span>
										<span class="w-20 flex justify-center">
											{{ formData.tertiaryApprovalStatusNm }}
										</span>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="bottom-btn-wrap">
				<DxButton
					text="결재요청"
					class="default filled txt_S medium"
					:width="90"
					:height="40"
					@click="onSaveFormData"
					:visible="!config.updateYn"
				/>
				<DxButton
					text="재기안"
					class="default filled txt_S medium"
					:width="90"
					:height="40"
					@click="onReSubmissionFormData"
					:visible="!config.reSubmission"
				/>
				<DxButton text="취	소" class="btn_XS white filled txt_S medium" :width="90" :height="40" @click="onCancelFormData" />
			</div>
		</section>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxDataGrid, DxColumn, DxLoadPanel, DxLookup, DxSelection } from 'devextreme-vue/data-grid';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { getPastFromToday, isSuccess, formatDate } from '@/plugins/common-lib';

let vm = this;

export default {
	components: {
		DxButton,
		DxDateBox,
		DxTextBox,
		DxTextArea,
		DxFileUploader,
		DxSelectBox,

		DxDataGrid,
		DxColumn,
		DxLoadPanel,
		DxLookup,
		DxSelection,

		DxRequiredRule,
		DxValidator,
	},
	data() {
		return {
			config: {
				stylingMode: 'outlined', //outlined, underlined, filled
				updateYn: false,
				reSubmission: false,
			},
			codes: {
				originAttendanceState: [
					{ id: 'normalFl', value: '정상' },
					{ id: 'tardinessFl', value: '지각' },
					{ id: 'absentFl', value: '결근' },
					{ id: 'dayoffFl', value: '조퇴' },
				],
				attendanceState: [],
			},
			formData: {},
			approval: {
				firstAgt: [],
				secondAgt: [],
				thirdtAgt: [],
			},
		};
	},
	computed: {},
	methods: {
		async attendanceLineList() {
			const payload = {
				actionname: 'EWM_PERSONAL_VACATION_LINE',
				data: {
					approvalType: 'ATTENDANCE',
				},
				loading: false,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.approval.firstAgt = res.data.data[0].approvalLine1;
				this.approval.secondAgt = res.data.data[0].approvalLine2;
				this.approval.thirdtAgt = res.data.data[0].approvalLine3;
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		async uploadFile(e) {
			const file = e.value[0];
			let formData = new FormData();
			formData.append('file', file);

			let payload = {
				actionname: 'COMMON_ATTACHED_FILE_UPLOAD',
				data: formData,
				loading: false,
			};

			try {
				let res = await this.CALL_API(payload);
				if (res.status === 200) {
					this.formData.attachFileGroupId = res.data.data[0].fileGroupId;
					this.formData.originalFileName = res.data.data[0].originalFileName;
				} else {
					this.$_Msg('이미지 업로드 실패 :(');
				}
			} catch (error) {
				this.$log.debug(error);
			}
		},
		onChangeState(value) {
			this.formData.editAttendanceState = value.value;

			if (value.value == 'normalFl') {
				//정상
				this.formData.loginTimeEdit = this.formData.workBasicStartTime;
				this.formData.logoutTimeEdit = this.formData.workBasicEndTime;
			} else if (value.value == 'tardinessFl') {
				//지각
				this.formData.loginTimeEdit = null;
				this.formData.logoutTimeEdit = this.formData.workBasicEndTime;
			} else if (value.value == 'dayoffFl') {
				//조퇴
				this.formData.loginTimeEdit = this.formData.workBasicStartTime;
				this.formData.logoutTimeEdit = null;
			} else if (value.value == 'absentFl') {
				//결근
				this.formData.loginTimeEdit = null;
				this.formData.logoutTimeEdit = null;
			}

			console.log('상태', this.formData.editAttendanceState);
			console.log('로그인', this.formData.loginTimeEdit);
			console.log('로그아웃', this.formData.logoutTimeEdit);
		},
		/** @description : 저장 메서드 */
		async onSaveFormData(e) {
			if (!e.validationGroup.validate().isValid) {
				return false;
			}
			
			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>휴가신청</h2>` }))) {
				return;
			}

			let approvalData = {
				approvalType: 'ATTENDANCE',
				requestId: this.formData.agtId,
				requestInfo: '기존출결: ' + this.formData.attendanceState + '|변경출결: ' + this.formData.editAttendanceState,
				requestReason: this.formData.approvalRequestReason,
				fileGroupId: this.formData.attachFileGroupId,
				primaryApproverId: this.formData.primaryApproverId,
				secondaryApproverId: this.formData.secondaryApproverId,
				tertiaryApproverId: this.formData.tertiaryApproverId,
			};

			const payload1 = {
				actionname: 'EWM_APPROVAL_REQUEST_INSERT',
				data: approvalData,
			};
			const res1 = await this.CALL_EWM_API(payload1);

			if (isSuccess(res1)) {
				let workTimeEditData = {
					approvalId: res1.data.data[0],
					ymd: this.formData.ymd,
					agtId: this.formData.agtId,
					scheId: this.formData.scheId,
					attendanceId: this.formData.attendanceId,
					normalEditFl: this.formData.editAttendanceState == 'normalFl' ? 'Y' : 'N',
					tardinessEditFl: this.formData.editAttendanceState == 'tardinessFl' ? 'Y' : 'N',
					absentEditFl: this.formData.editAttendanceState == 'absentFl' ? 'Y' : 'N',
					dayoffEditFl: this.formData.editAttendanceState == 'dayoffFl' ? 'Y' : 'N',
					loginTimeEdit: this.formData.loginTimeEdit,
					logoutTimeEdit: this.formData.logoutTimeEdit,
					description: this.formData.approvalRequestReason,
				};

				const payload2 = {
					actionname: 'EWM_PERSONAL_ATTENDANCE_SAVE',
					data: workTimeEditData,
				};

				const res2 = await this.CALL_EWM_API(payload2);

				if (isSuccess(res2)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					this.onCancelFormData();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		async onReSubmissionFormData(e) {
			if (!e.validationGroup.validate().isValid) {
				return false;
			}

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>근무변경신청</h2>` }))) {
				return;
			}
			const payload1 = {
				actionname: 'EWM_APPROVAL_PROCESSING_UPDATE',
				path: '/' + this.formData.approvalId,
				data: {
					approvalStatus: 'RE_SUBMISSION',
					approvalReason: this.formData.approvalRequestReason,
					requestID: this.formData.agtId,
					requestReason: this.formData.approvalRequestReason,
					fileGroupId: this.formData.attachFileGroupId,
					primaryApproverId: this.formData.primaryApproverId,
					secondaryApproverId: this.formData.secondaryApproverId,
					tertiaryApproverId: this.formData.tertiaryApproverId,
				},
			};

			const res1 = await this.CALL_EWM_API(payload1);

			if (isSuccess(res1)) {
				let workTimeEditData = {
					id: this.formData.attendanceWorkTimeEditId,
					ymd: this.formData.ymd,
					agtId: this.formData.agtId,
					scheId: this.formData.scheId,
					attendanceId: this.formData.attendanceId,
					approvalId: this.formData.approvalId,
					normalEditFl: this.formData.editAttendanceState == 'normalFl' ? 'Y' : 'N',
					tardinessEditFl: this.formData.editAttendanceState == 'tardinessFl' ? 'Y' : 'N',
					absentEditFl: this.formData.editAttendanceState == 'absentFl' ? 'Y' : 'N',
					dayoffEditFl: this.formData.editAttendanceState == 'dayoffFl' ? 'Y' : 'N',
					loginTimeEdit: formatDate(this.formData.loginTimeEdit, 'HH:mm', 'HHmm'),
					logoutTimeEdit: formatDate(this.formData.logoutTimeEdit, 'HH:mm', 'HHmm'),
					description: this.formData.approvalRequestReason,
				};

				console.log(workTimeEditData);

				const payload2 = {
					actionname: 'EWM_PERSONAL_ATTENDANCE_SAVE',
					data: workTimeEditData,
				};

				const res2 = await this.CALL_EWM_API(payload2);

				if (isSuccess(res2)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					this.onCancelFormData();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		onCancelFormData() {
			this.$router.push({ path: '/ewm/personal/worktime-edit' });
		},
		setAttendanceState(formData) {
			if (formData.normalFl == 'Y') {
				this.formData.attendanceState = 'normalFl';
			}
			if (formData.tardinessFl == 'Y') {
				formData.attendanceState = 'tardinessFl';
			}
			if (formData.absentFl == 'Y') {
				formData.attendanceState = 'absentFl';
			}
			if (formData.dayoffFl == 'Y') {
				formData.attendanceState = 'dayoffFl';
			}

			if (formData.normalEditFl == 'Y') {
				formData.editAttendanceState = 'normalFl';
			}
			if (formData.tardinessEditFl == 'Y') {
				formData.editAttendanceState = 'tardinessFl';
			}
			if (formData.absentEditFl == 'Y') {
				formData.editAttendanceState = 'absentFl';
			}
			if (formData.dayoffEditFl == 'Y') {
				formData.editAttendanceState = 'dayoffFl';
			}

			this.codes.attendanceState = this.codes.originAttendanceState.filter(item => item.id !== formData.attendanceState);
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

			this.reqParams = this.$store.getters.getDetailParams;

			this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;
			this.config.reSubmission = this.reqParams ? this.reqParams.reSubmission : false;
			this.formData = this.reqParams.formData;
			this.formData.primaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(e => this.formData.primaryApprovalStatus === e.value)?.label;
			this.formData.secondaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(e => this.formData.secondaryApprovalStatus === e.value)?.label;
			this.formData.tertiaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(e => this.formData.tertiaryApprovalStatus === e.value)?.label;

			this.setAttendanceState(this.formData);
			this.attendanceLineList(); //결재라인 조회
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
